// The actions file containing the various methods to be invoked to get data for the nav menu
import ArticlesService from '@/server/contentful/articles.service'
import ExpertAnalysisService from '@/server/contentful/expert-analysis/service'

import { EXPERT_EASE_CATEGORIES } from '~/constants/pages/contentful'

const actions = {
  async fetchMenuArticles (params) {
    const articlesService = new ArticlesService()
    const expertAnalysisService = new ExpertAnalysisService()

    const verticals = params?.verticals || []
    const limit = verticals.length * 5
    const skip = 0

    const articles = await articlesService.fetchFeaturedArticlesByVertical(verticals, limit, skip).then(response => response.items)

    // Get the top news items
    const topNews = await expertAnalysisService.getPostsByCategory({
      categories: [
        'Top News',
        ...Object.keys(EXPERT_EASE_CATEGORIES)
      ],
      limit: 4
    }).then(response => { 
      const news = response.top_news

      return [
        news.featured,
        ...(news.items || [])
      ]
    })

    const articlesByVertical = articles.reduce((result, value) => {
      const verticalValue = value.vertical === 'Health Insurance' && value.isOvhc
        ? 'Overseas Visitor Health Cover'
        : value.vertical

      if (!(verticalValue in result)) {
        result[verticalValue] = []
      }

      result[verticalValue].push(value)
      return result
    }, {})

    // Add the top news to Expert Analysis
    articlesByVertical['Expert Analysis'] = topNews

    // Set the updated state value
    this.$state.featuredMenu = articlesByVertical
  }
}

export default actions
