<template>
  <div v-if="date && show">
    <atoms-alert
      :type="!comparisonPage ? 'info' : 'warning'"
      class="text-left md:text-center !text-xs/[1.125rem] md:!text-sm !px-4 md:!py-[0.625rem]"
      :class="{
        'md:border-y-0': !comparisonPage
      }"
      :rounded="false"
      full-width
    >
      <template v-if="customerAfterHourPopupEnabled">
        Up to <strong>$50 gift card</strong> when you take out health cover – Today only!
        <a
          href="https://lp.compareclub.com.au/terms_and_conditions_50_25_offer_sunday_march_30_2025_only/"
          class="underline"
          target="_blank">T&C’s apply</a>
      </template>
      <template v-else>
        <div v-if="!comparisonPage">
          Health premiums go up from {{ formattedDate.dayMonth }} so our experts are available later in the day to help you switch. Call us:

          {{ schedule }}

          <template v-if="scheduleWeekend">
            &
          </template>

          {{ scheduleWeekend }}.

          <nuxt-link
            id="rate-rise-tile-find-out-more"
            class="underline hover:cursor-pointer font-bold"
            to="https://compareclub.com.au/health-insurance/health-insurance-premium-increase/"
            target="_blank"
            @click="$event => $tracking.click($event)"
          >
            Find out more
          </nuxt-link>
        </div>
        <div v-else>
          Health insurance <strong>premiums for funds will increase</strong> <br class="block md:hidden" />
          on {{ formattedDate.dayMonthYear }}.
          <strong
            class="underline inline-block cursor-pointer"
            @click="() => rateRiseModalRef.toggle(true)"
          >
            Click here
          </strong>
          to learn more
        </div>
      </template>
    </atoms-alert>

    <molecules-modal
      ref="rateRiseModalRef"
      class="!items-start pt-[5.5rem] md:pt-[9rem]"
      card-class="max-w-[40.9375rem] py-6 px-[1.0625rem] !m-0"
      :overlay-clickable="false"
    >
      <p class="font-bold text-lg/[1.625rem] md:text-xl mb-4 md:mb-[1.0625rem]">
        Why do my health insurance premiums increase every year?
      </p>

      <div class="bg-white border border-gray-400 rounded-2xl py-3 px-2 md:pl-5 md:pr-3 text-sm md:text-base mb-4 md:mb-6">
        <div class="overflow-auto max-h-[22.25rem] md:max-h-[27.9375rem] flex flex-col gap-y-5 md:gap-y-7 pr-2 md:pr-3">
          <p>
            Private health funds increase insurance premiums each year to keep up with rising healthcare costs in Australia. 
          </p>
          <p>Here’s why:</p>

          <ul class="list-disc ml-4">
            <li>
              New treatments and medical advancements often cost more.
            </li>

            <li>
              Australia has an aging population, which means there’s more pressure on health insurers.
            </li>

            <li>
              Prices charged by hospitals, doctors, and specialists continue to rise.
            </li>
          </ul>

          <p>
            Premium increases have to be approved by the federal government. Their role is to make sure insurers aren’t pricing people out of the market but can still offer a broad range of services and benefits.
          </p>
          <p class="font-bold">How much will premiums go up by in 2025?</p>
          <p>Health insurance premiums will increase by an average of <b>3.73%</b> on April 1. This is the highest premium cost increase since 2018 when premiums rose by an average of 3.95%.</p>

          <ul class="list-disc ml-4">
            <li>
              Family: +$184*
            </li>

            <li>
              Couple: +$208*
            </li>

            <li>
              Single: +$95*
            </li>

            <li>
              Single parent family: +$139*
            </li>
          </ul>

          <p>
            Of course, some policies may see higher increases, while others could rise by less.
          </p>

          <small class="text-red-500">
            *Numbers calculated based on average premiums sold by Compare Club over the past 5 years (2019 to 2024).
          </small>

          <small>
            Compare Club Australia Pty Ltd ABN 29 634 600 007 of 222 Pitt Street Sydney (Ph: 1300 904 624) owns and operates compareclub.com.au and its associated websites. The information contained in this email is of general nature only and has been prepared without taking into consideration your objectives, needs and financial situation. We compare selected products from a panel of trusted insurers. We do not compare all products in the market. 
          </small>
        </div>
      </div>

      <atoms-button
        theme="secondary"
        :size="devices.breakpoint('md') ? 'small' : 'medium'"
        class="mx-auto w-full md:w-auto"
        @click="rateRiseModalRef.toggle(false)"
      >
        CLOSE
      </atoms-button>
    </molecules-modal>

    <div
      v-if="showTimer"
      class="fixed pl-4 pr-[22px] md:pr-[20px] w-full flex justify-end pointer-events-none transition-all"
      :style="{
        bottom: component.position
      }"
    >
      <div
        class="bg-[linear-gradient(83.93deg,_#193560_-31.02%,_#3EC58F_126.9%)] max-w-[382px] sm:max-w-[275px] text-white pointer-events-auto flex flex-col xs:flex-row sm:flex-col gap-x-2 gap-y-1 sm:gap-y-2 relative xs:items-center sm:items-start"
        :class="{
          'rounded-full w-auto': toggleCountdown,
          'rounded-lg w-full pt-[0.875rem] pb-[0.625rem] px-4 sm:pr-6': !toggleCountdown,
        }"
      >
        <template v-if="!toggleCountdown">
          <img
            src="/icons/close-circle-light.svg"
            class="absolute top-1 right-1 cursor-pointer hover:opacity-70"
            alt="counter close"
            @click="toggleCountdown = !toggleCountdown"
          />

          <p class="text-[9px]/[12.28px] xs:text-2xs/[0.8525rem] text-center xs:text-left md:text-sm">
            Get in before the <strong>health insurance premium</strong> increase
            <br
              v-if="devices.breakpoint('xxs', 'max')"
              class="block xxs:hidden"
            />
            on {{ formattedDate.dayMonthYear }}
          </p>

          <ul class="flex justify-center items-center [&>div]:text-center">
            <li
              v-for="(time, timeKey) in countdown"
              :key="timeKey"
              class="text-center flex relative"
            >
              <div>
                <div :class="component.time">
                  {{ time }}
                </div>

                <div :class="component.timeLabel">
                  {{ timeKey }}
                </div>
              </div>

              <div
                v-if="timeKey !== 'Seconds'"
                :class="[
                  component.semicolon,
                  {
                    'mx-5 xs:mx-[6px] sm:mx-[8px]': timeKey === 'Days',
                    'ml-5 mr-4 xs:mx-[6px] sm:mx-[8px]': timeKey === 'Hour',
                    'ml-4 mr-[0.875rem] xs:ml-[3px] xs:mr-[4px] sm:ml-[5px] sm:mr-[8px]': timeKey === 'Minutes'
                  }
                ]"
              >
                :
              </div>
            </li>
          </ul>
        </template>

        <div
          v-else
          class="cursor-pointer p-3 hover:opacity-70"
          @click="toggleCountdown = !toggleCountdown"
        >
          <img src="/icons/clock-light.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'

import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'MoleculesRateRiseNudgeBar'
})

const props = defineProps({
  config: {
    type: [String, Object],
    default: () => ({})
  },

  show: {
    type: Boolean,
    default: false
  }
})

const { $tracking } = useNuxtApp()
const $route = useRoute()
const { devices } = deviceChecker()

const { customerAfterHourPopupEnabled } = useEnv()

const schedule = ref(VERTICALS[VERTICAL.HEALTH_INSURANCE].schedule)
const scheduleWeekend = ref(VERTICALS[VERTICAL.HEALTH_INSURANCE].schedule_weekend)

const rateRiseModalRef = ref()
const toggleCountdown = ref(false)
const countdown = ref({
  Days: '00',
  Hour: '00',
  Minutes: '00',
  Seconds: '00'
})

const comparisonPage = computed(() => (['health-insurance-quote-resume', 'health-insurance-quotes-results'].includes($route.name)))

const showTimer = computed(() => {
  if (comparisonPage.value) {
    return false
  }

  if (
    $route.name === 'health-insurance-quote-form-step' &&
    $route.params.step === 'step1' &&
    devices.breakpoint('lg', 'max')
  ) {
    return false
  }

  return true
})

const component = computed(() => {
  const time = 'text-2xl sm:text-3.5xl font-bold'
  const timeLabel = 'text-2xs/[0.8525rem] sm:text-xs/[1.125rem] -mt-[0.375rem] sm:-mt-[0.5rem]'
  const semicolon = 'text-2xl md:text-3.5xl font-bold relative -top-[0.0625rem]'

  const position = '80px'

  return {
    time,
    timeLabel,
    semicolon,
    position
  }
})

const date = computed(() => {
  const dates = []
  const config = !_isEmpty(props.config)
    ? parsify(props.config, {})
    : {}

  for (const key in config) {
    if (Object.prototype.hasOwnProperty.call(config, key)) {
      const item = config?.[key]

      if (item?.date) {
        dates.push(item.date)
      }
    }
  }

  if (_isEmpty(dates)) {
    return null
  }

  const tempDate = dates.sort((a, b) => dayjs(b).isBefore(dayjs(a)))?.[0]

  return dayjs(tempDate)
})

const formattedDate = computed(() => {
  return {
    dayMonth: (date.value && date.value.format('D MMMM')) || '',
    dayMonthYear: (date.value && date.value.format('D MMMM YYYY')) || ''
  }
})

function updateCountdown () {
  const interval = setInterval(() => {
    const now = new Date(dayjs()).getTime()
    const future = new Date(date.value).getTime()
    const diff = future - now

    const _days = Math.floor(diff / (1000 * 60 * 60 * 24)).toString()
    const _hour = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString()
    const _minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)).toString()
    const _seconds = Math.floor((diff % (1000 * 60)) / 1000).toString()

    countdown.value.Days = _days.length === 1
      ? `0${_days}`
      : _days
    countdown.value.Hour = _hour.length === 1
      ? `0${_hour}`
      : _hour
    countdown.value.Minutes = _minutes.length === 1
      ? `0${_minutes}`
      : _minutes
    countdown.value.Seconds = _seconds.length === 1
      ? `0${_seconds}`
      : _seconds

    if (diff < 0) {
      clearInterval(interval)

      countdown.value.Days = '00'
      countdown.value.Hour = '00'
      countdown.value.Minutes = '00'
      countdown.value.Seconds = '00'
    }
  }, 1000)
}

onMounted(() => {
  updateCountdown()
})
</script>
