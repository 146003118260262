<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    v-show="showCurrMenu"
    class="border-gray-400 lg:border-t"
  >
    <!--
      Loop through the menu object
      to make all navigation items crawlable
    -->
    <div  
      v-for="(menuItem, menuIndex) in menuObj"
      v-show="currMenu === menuIndex"
      :key="menuIndex"
      class="outline-none w-full left-0 z-50"
      tabindex="0"
    >
      <!-- PC MAIN SUBMENU -->
      <atoms-menu
        v-if="!_isEmpty(menuItem.subMenuArr)"
        class="hidden lg:flex lg:justify-center border-gray-400 border-b"
      >
        <atoms-menu-item
          v-for="(item, itemKey) in menuItem.subMenuArr"
          :key="itemKey"
          :has-background-when-active="false"
          :arrow="false"
          :active="item === currSubMenu"
          :theme="
            menuItem.subMenuObj[item]?.to
              ? 'global-navbar'
              : 'hover-with-border'
          "
          :to="menuItem.subMenuObj[item]?.to || null"
          padding="xLarge"
          class="!text-dark"
          @click.stop="setSubMenu(item)"
        >
          {{ item }}
        </atoms-menu-item>
      </atoms-menu>

      <div
        v-for="(subMenuItem, subMenuIndex) in menuItem.subMenuObj"
        :key="subMenuIndex"
        class="lg:[background-image:linear-gradient(to_right,#fff_0%,#fff_50%,#f9f9f9_50%,#f9f9f9_100%)]"
      >
        <!-- SUBMENU TITLE MOBILE -->
        <div
          v-if="!_isEmpty(menuItem.subMenuArr)"
          class="flex lg:hidden text-dark w-full text-left font-normal px-4 py-5 gap-2 border-t lg:border-b"
          :class="{
            '!font-bold justify-start border-gray-20': currSubMenu === subMenuIndex,
            'justify-between border-gray-400': currSubMenu !== subMenuIndex,
            '!hidden': currSubMenu && currSubMenu !== subMenuIndex
          }"
          @click.stop="setSubMenu(subMenuIndex)"
        >
          {{ subMenuItem.title }}

          <IconsChevron
            :class="currSubMenu === subMenuIndex ? 'order-first' : ''"
            :direction="currSubMenu === subMenuIndex ? 'left' : 'right'"
            size="lg"
          />
        </div>

        <!-- SUBMENU CONTENT -->
        <div
          v-show="currSubMenu === subMenuIndex"
          class="w-full max-w-wrapper-lg mx-auto lg:px-8 grid lg:grid-cols-2 lg:grid-rows-[auto,90px] lg:gap-y-8"
        > 
          <div
            v-for="(section, sectionIndex) in subMenuItem.cols"
            :key="sectionIndex"
            :class="{
              'lg:pt-6 grid': sectionIndex === 0, //lg:pr-6
              'lg:grid-cols-2 lg:gap-x-8': sectionIndex === 0 && section.length === 2,
              'lg:bg-gray-10 lg:pl-8 lg:py-6 lg:row-span-2' : sectionIndex === 1,
            }"
          >
            <div
              v-for="(cols, colsIndex) in section"
              :key="colsIndex"
              class="flex flex-col lg:gap-y-6"
            >
              <div
                v-for="(colItem, colItemIndex) in cols"
                :key="colItemIndex"
                class="lg:flex lg:flex-col lg:gap-6"
                @click.stop
              >
                <input
                  :id="strToKebabCase(`${colItem?.title}${subMenuItem?.title}`)"
                  v-model="currSubMenuCol"
                  type="radio"
                  :name="strToKebabCase(subMenuItem?.title)"
                  class="peer hidden appearance-none"
                  :value="colItem?.title"
                  :checked="colItem?.title === currSubMenuCol"
                />

                <label
                  class="
                    flex lg:text-xl font-bold bg-gray-10 border-gray-20 border-t lg:border-t-0 lg:border-b-0 lg:bg-transparent py-4 px-5 lg:p-0 lg:min-h-[1.75rem] 
                    
                    lg:before:hidden before:inline-block before:font-bold before:text-2xl before:mr-2 before:relative before:leading-none before:-top-[0.125rem] before:w-[0.875rem] before:content-['+']
                    
                    peer-checked:before:content-['-'] peer-checked:border-b lg:peer-checked:border-b-0
                  "
                  :for="strToKebabCase(`${colItem?.title}${subMenuItem?.title}`)"
                >
                  {{ colItem?.title }}
                </label>

                <div
                  class="flex flex-col items-start gap-2 "
                  :class="{
                    'py-4 px-8 lg:p-0': sectionIndex === 0,
                    'lg:grid lg:grid-cols-2': ['Credit Cards', 'Expert Analysis'].includes(subMenuItem.title) && sectionIndex === 0,
                    'hidden lg:flex': devices.breakpoint('md', 'max') && currSubMenuCol !== colItem?.title
                  }"
                >
                  <template v-if="sectionIndex === 0">
                    <nuxt-link
                      v-for="(link, linkKey) in colItem?.links"
                      :key="linkKey"
                      :to="link?.url"
                      class="w-full lg:w-auto p-1 hover:bg-gray-200 hover:font-bold hover:rounded-[1rem] flex -ml-1 hover:cursor-pointer"
                      @click="() => emit('redirect', { type: 'click' })"
                    >
                      <NuxtImg 
                        v-if="link?.image"
                        width="24"
                        height="24"
                        loading="lazy"
                        class="exact-wh-6 mr-4"
                        :src="link?.image"
                        :alt="`cta-inline-${link?.title}`"
                      />
        
                      <span
                        class="mr-2"
                        v-html="link?.title"
                      >
                      </span>

                      <IconsChevron
                        size="default"
                        class="exact-wh-6"
                        width="24"
                        height="24"
                      />
                    </nuxt-link>
                  </template>
                
                  <div
                    v-else
                    class="lg:grid lg:grid-cols-2 lg:gap-4"
                  >
                    <nuxt-link
                      v-for="(link, linkKey) in colItem?.links"
                      :key="linkKey"
                      :to="link.url"  
                      class="min-h-[5.5rem] overflow-hidden lg:bg-white lg:rounded-md lg:border-1 lg:border-white lg:hover:border-primary hover:cursor-pointer px-8 lg:p-2 text-xs lg:text-base font-semibold"
                      :class="{
                        'grid grid-cols-[3.5rem,1fr] lg:grid-cols-[3.75rem,1fr] gap-x-[1.125rem] items-center': !link.underline,
                        'flex items-center justify-center': link.underline,
                        'border-b border-gray-20': linkKey !== colItem.links.length - 1
                      }"
                      @click="() => emit('redirect', { type: 'click' })"
                    >
                      <NuxtImg
                        v-if="link.image"
                        :alt="`cta-inline-partner-${link.title.toLowerCase()}`"
                        :src="link.image.split('?')[0] + '?w=72&h=72&fit=fill&r=8&fm=webp'"
                        width="72"
                        height="72"
                        class="max-h-72"
                      />

                      <div
                        class="flex items-center"
                        :class="{
                          'justify-center items-center underline uppercase gap-2': link.underline
                        }"
                      >
                        {{ link?.title }}

                        <IconsChevron
                          v-if="link.underline"
                          class="inline-block"
                          height="12"
                          width="17"
                        />
                      </div>
                    </nuxt-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="lg:text-lg border-t border-gray-20 py-4 px-4 lg:px-0 lg:pt-6 lg:pb-10 lg:mr-6">
            Find out more about

            <nuxt-link
              :to="subMenuItem.details?.productPage"
              class="cursor-pointer"
              @click="() => emit('redirect', { type: 'click' })"
            >
              <span class="font-bold underline">
                {{ subMenuItem.title }}
              </span>

              <NuxtImg
                src="/icons/arrow-line-right.svg"
                width="20"
                height="10"
                class="inline ml-1"
                alt="arrow"
              />
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  
    <div class="lg:hidden border-t border-gray-20 grid grid-cols-2 px-2 py-5 gap-4 items-start">
      <atoms-cta
        class="uppercase whitespace-nowrap !h-auto"
        :to="!userLoggedIn ? '/register/' : '/dashboard/'"
        :theme="!userLoggedIn ? 'destructive' : 'secondary'"
        size="mini"
        full
      >
        {{
          !userLoggedIn
            ? 'Join the Club'
            : 'View Dashboard'
        }}
      </atoms-cta>

      <div
        v-if="hasCallCta"
        class="flex flex-col text-center gap-2"
      >
        <atoms-cta
          :to="`tel:${verticalData?.phoneNumber}`"
          theme="primary"
          size="mini"
          class="w-full uppercase justify-center text-center inline-flex items-center whitespace-nowrap"
          full
        >
          <NuxtImg
            src="/icons/phone-dark.svg"
            alt="phone"
            width="12"
            height="12"
            class="mr-2"
          />

          <span>Speak with us</span>
        </atoms-cta>

        <div class="text-xs font-bold">
          <p>
            {{ verticalData.schedule }}
          </p>

          <p>
            {{ verticalData.schedule_weekend }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'
import _flatten from 'underscore/cjs/flatten.js'

import { useNavigationStore } from '@/stores/navigation'
const { featuredMenuItems } = useNavigationStore()

const { devices } = deviceChecker()

defineOptions({
  name: 'OrganismsNavMegaMenu'
})

const props = defineProps({
  menu: {
    type: Object,
    default: () => ({}),
    required: true
  },

  showCurrMenu: {
    type: Boolean,
    default: false
  },

  currMenu: {
    type: String,
    default: ''
  },

  hasCallCta: {
    type: Boolean,
    default: false
  },

  userLoggedIn: {
    type: Boolean,
    default: false
  },

  verticalData: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits([
  'redirect'
])

const currSubMenu = ref('')
const currSubMenuCol = ref('')

const menuObj = computed(() => { 
  return Object.keys(props.menu)
    .reduce((curr, key) => {
      const temp = Object.assign({}, curr)

      const item = props.menu[key]
      const subMenuKey = Object.keys(item.menu)
      const subMenuArr = subMenuKey.length > 1
        ? subMenuKey
        : []

      const menu = JSON.parse(JSON.stringify(props.menu[key].menu))

      const subMenuObj = Object.keys(menu).reduce((_curr, _key) => {
        const _temp = Object.assign({}, _curr)
        
        const guideLinks = (() => { 
          const _posts = featuredMenuItems?.[_key] ?? []
          const _default = menu[_key]?.thirdColumn?.[0]?.links

          if (!(_default instanceof Array && _default.length)) {
            return _posts
          }

          return [..._posts, ..._default]
        })()

        if (menu[_key]?.thirdColumn?.[0]?.links) {
          menu[_key].thirdColumn[0].links = guideLinks
        }

        /**
         * restructure first, second, and third column
         * into cols array so its earlier to loop
         */
        const cols = [
          ([
            menu[_key]?.firstColumn || null,
            menu[_key]?.secondColumn || null
          ]).filter(_x => _x),
          [
            menu[_key]?.thirdColumn || null
          ].filter(_x => _x)
        ]

        _temp[_key] = {
          title: menu[_key].title,
          details: menu[_key].details,
          
          cols,
          colsTitleArr: _flatten(cols)
            .filter(_x => _x)
            .map(_x => _x.title)
        }

        return _temp
      }, {})

      temp[key] = {
        subMenuArr,
        subMenuObj,
        slug: item.slug,
        theme: item.theme,
        type: item.type,
        title: item.title
      }

      return temp
    }, {})
})

watch(() => props.currMenu, value => {
  if (!value) {
    currSubMenu.value = ''
  } else {
    const subMenuArr = Object.keys(menuObj.value[value]?.subMenuObj)

    if (devices.breakpoint('lg')) { 
      currSubMenu.value = subMenuArr.length > 0
        ? subMenuArr[0]
        : ''
    } else {
      if (['energy', 'expert_analysis'].includes(props.currMenu)) {
        currSubMenu.value = subMenuArr.length > 0
          ? subMenuArr[0]
          : ''

        if (currSubMenu.value) {
          preSelectSubCol(currSubMenu.value)
        }
      } else {
        currSubMenu.value = ''
      }
    }
  }
})

function setSubMenu (selected) {
  // if mobile it toggles
  if (devices.breakpoint('md', 'max')) {
    currSubMenu.value = currSubMenu.value === selected
      ? ''
      : selected
  } else {
    currSubMenu.value = selected
  }

  preSelectSubCol(selected)
}

function preSelectSubCol (selected) {
  // pre-select first sub menu row item
  const subMenu = menuObj.value[props.currMenu]?.subMenuObj?.[selected]?.colsTitleArr

  currSubMenuCol.value = subMenu?.[0]
}
</script>
